;
function Main() {
    'use strict';
    this.constructor = function () {
        this.selectors();
        this.events();
        this.contact();
        this.slider();
        this.partners();
        this.lists();
                //this.forms();
    };
    this.init = function () {
        this.constructor();
        console.log("Main::init()");
    };
    this.selectors = function () {

        this.$button = $("#searcher-button");
        this.$shareButton = $(".icon-share");
        this.$socialContainer = $(".social");
        this.$removeElementButton = $('.remove-element');

    };
    this.events = function () {

        var that = this;
        console.log("Chocolat::init()");
        $('.chocolat-parent').Chocolat();
        this.$shareButton.on('click', function (e) {
            e.preventDefault();
            var $this = $(this);

            if (that.$socialContainer.hasClass('open')) {

                that.$socialContainer.slideUp({duration: 300, queue: false, complete: function () {
                        $(this).removeClass('open');
                    }
                });
                //that.$socialContainer.slideUp({duration: 300, queue: false});

            } else {

                //that.$socialContainer.slideDown({duration: 300, queue: false});
                that.$socialContainer.fadeIn({duration: 300, queue: false, complete: function () {
                        $(this).addClass('open');
                    }
                });


            }

            var $container = that.$socialContainer
                    .siblings('.toggle-element');

            $container.slideUp({duration: 300, queue: false, complete: function () {
                    $(this).removeClass('open');
                }});
            //$container.slideUp({duration: 300, queue: false});

        });

        this.$removeElementButton.on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            if ($this.data('target-class')) {
                $('.' + $this.data('target-class')).slideUp();
            }
        });

    };
    this.contact = function () {
        console.log('App::contact()');
        var that = this;

        var cho = $(".chosen").chosen({
            disable_search_threshold: 10,
            width: '100%',
            browser_is_supported: function () {
                alert(1);
            }
        });

        console.log('App::chosen()');

        $('.contact-form').submit(function (e) {
            var $this = $(this);
            e.preventDefault();
            console.log('App::contact::submit');
            $('body').addClass('busy');
            var data = $(this).serialize();
            $.ajax({
                type: "POST",
                url: $this.attr('action'),
                data: data,
                success: function (result) {
                    if (result.status == 'subscriber added') {
                        that.growl(
                                'Wysłano<br>Twoje zapytanie do newslettera zostało przesłane'
                                );
                        $('.contact-input').val('');
                    } else if (result.status == 'send') {
                        that.growl(
                                'Wysłano<br>Twoje zapytanie kontaktowe zostało przesłane'
                                );
                        $('.contact-input').val('');
                    } else if (result.status == 'error') {
                        that.growl(
                                'Niepowodzenie<br>Niestety coś poszło nie tak. Spróbuj ponownie później.'
                                );
                    }
                    $('body').removeClass('busy');
                }
            });
        });
    };

    this.slider = function () {
        if ($('.swiper-container').length > 0) {
            var swiper = new Swiper('.swiper-container', {
                pagination: '.swiper-pagination',
                paginationClickable: true,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                autoplay: {
                    delay: 4000,
                  },
            });
        }
    };
    this.partners = function () {
        if ($('.swiper-container-gdzie').length > 0) {
            var swiperGdzie = new Swiper('.swiper-container-gdzie', {
                slidesPerView: 3,
                spaceBetween: 0,
                autoplay: {
                    delay: 2000,
                  },
                loop: true
            });
        }
    };

    this.lists = function () {

        console.log("Lists::init()");
        if ($('.paginated-list').length > 0) {
            console.log("Lists::run()");
            Promise.resolve(new List('paginated-list', {
                valueNames: ['media-body'],
                page: 9,
                pagination: true
            })).then(function (result) {
                console.log(result);

                if (result.page >= result.items.length) {
                    $(".pagination").hide();
                }
                $('.paginated-list').css({'display': 'initial'});
            });
        }
    };


    this.growl = function (message) {
        $.blockUI({
            message: message,
            fadeIn: 700,
            fadeOut: 700,
            timeout: 2000,
            showOverlay: false,
            centerY: false,
            css: {
                width: '350px',
                top: '10px',
                left: '',
                right: '10px',
                border: 'none',
                padding: '5px',
                backgroundColor: '#000',
                '-webkit-border-radius': '10px',
                '-moz-border-radius': '10px',
                opacity: .6,
                color: '#fff',
                'z-index': 2000,
            }
        });
    }
}
;
var main = new Main();

$(function () {
    main.init();
});
