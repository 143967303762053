function Searcher() {
    'use strict';
    this.constructor = function () {
        this.selectors();
        this.events();

    };
    this.init = function () {
        this.constructor();
        console.log("Searcher::init()");
    };

    this.selectors = function () {
        this.$searchbutton = $("#searcher-button");
        this.$searchFormContainer = $(".find");
        this.$searchFormInput = $("#searchword-input");
    };
    this.events = function () {
        var that = this;
        this.$searchbutton.on('click', function () {
            console.log($(this));
            var $this = $(this);

            if (that.$searchFormContainer.hasClass('open')) {

                that.$searchFormContainer.slideUp({duration: 300, queue: false, complete: function () {
                        $(this).removeClass('open');
                    }
                });
                //that.$searchFormContainer.slideUp({duration: 300, queue: false});

            } else {
                
                //that.$searchFormContainer.slideDown({duration: 300, queue: false});
                that.$searchFormContainer.fadeIn({duration: 300, queue: false, complete: function () {
                        $(this).addClass('open');
                    }
                });
            }

            var $container = that.$searchFormContainer
                    .siblings('.toggle-element');

            $container.slideUp({duration: 300, queue: false, complete: function () {
                    $(this).removeClass('open');
                }});
            //$container.slideUp({duration: 300, queue: false});


        });

        console.log(_.isEmpty(this.$searchFormInput.val()));
        if (_.isEmpty(this.$searchFormInput.val()) == false) {
            this.$searchFormContainer.show();
        }

        $('#search-form.ajax').submit(function (e) {
            e.preventDefault();
            that.processSearch();
        });
    }
    this.processSearch = function () {
        var that = this;
        console.log('App:: processSearch()')
        $.get('/' + Asgard.locale + '/searcher/search', $("#search-form").serialize(), function (data) {
            console.log(Asgard.locale);
            that.processSearchReply(data);
        }, 'json');
    }

    this.processSearchReply = function (data) {

        console.log('App:: processSearchReply')

        $('#search-modal').find('.modal-body').html("");

        $('#search-modal').modal('show');

        if (data && data.status && data.results) {

            if (data.status == 'success') {

                var $result = $('<div class="media">');

                console.log(data.results);

                _.forEach(data.results, function (value, key) {
                    console.log(value);
                    $result.
                            append('<div class=media><div class="media-left"><a href="#"><img class="media-object" src="' + value.thumb + '" alt="..."></a></div><div class="media-body"><h4 class="media-heading"><a href=' + value.url + ' target="_blank">' + value.title + '</a></h4><p>' + value.body + '</p></div></div>');
                });

                console.log($result);

                $('#search-modal').find('.modal-body').append($result);

                if (data.paginator) {
                    $('#search-modal').find('.modal-body').append(data.paginator);
                }

                $('#search-modal').modal('show');


            } else {


            }
        }
    }

}
;
var searcher = new Searcher();
searcher.init();