;
function Maps() {
    'use strict';
    this.constructor = function () {

    };
    this.init = function () {
        var that = this;
        this.constructor();
        console.log("Maps::init()");

        if ($("#minimap").length > 0 || $("#map").length > 0) {
            $.getScript('https://maps.googleapis.com/maps/api/js?key=' + Asgard.google_key, function () {
                if ($("#minimap").length > 0) {
                    that.miniMap();
                }
                if ($("#map").length > 0) {
                    that.contactMap();
                }
            });
        }
    };

    this.contactMap = function () {
        var uluru = {lat: 50.047457, lng: 21.986769};

        var center_map = {lat: 50.047457, lng: 21.958413};

        var content1 = "<div class='google-maps-pin google-maps-pin_first'><div class='google-maps-pin__title'></div></div>";

        var styles = new google.maps.StyledMapType([
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }

        ],
                {
                    name: "Style"
                });

        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 14,
            center: center_map,
            scrollwheel: false,
            mapTypeControlOptions: {
                mapTypeIds: []
            }
        });

        map.mapTypes.set("styles", styles);
        map.setMapTypeId('styles');

        var infowindow1 = new google.maps.InfoWindow({
            content: content1
        });


        var image = {
            url: '/themes/flatly/img/marker.png'
        };

        var marker = new google.maps.Marker({
            position: uluru,
            map: map,
            animation: google.maps.Animation.DROP,
            icon: image
        });

        infowindow1.open(map, marker);

        map.addListener('tilesloaded', function () {

            //$("<a>").append('<img>').attr('src', 'https://www.cartell.ie/car_check/wp-content/uploads/2015/11/favicon.ico');

            var $img = $('<img>').attr('src', '/themes/flatly/img/ico_car.png');
            var $a = $('<a>').css({
                right: '25px', 
                bottom: '20px', 
                position: 'absolute',
                'background-color':'#fff',
                padding:'2px',
                'border-radius':'6px',
                'z-index':'1'
            }).attr('target','_blank').attr('href', 'https://www.google.pl/maps/dir//Syriusz+Sp.+z+o.o.+Kasy+fiskalne,+oprogramowanie,+Legionów+35,+Rzeszów/@50.0423969,21.9165954,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x473cfb0e60243791:0xd8146e510c942474!2m2!1d21.986635!2d50.042418?hl=pl');
            $img.appendTo($a);

            $('.google-maps-pin_first')
                    .css({
                        width: '168px',
                        height: '125px'
                    })

                    .css({
                        backgroundImage: "url(/themes/flatly/img/kontakt.jpg)",
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }).append($a);
        });
    };

    this.miniMap = function () {

        var uluru = {lat: 50.042423, lng: 21.986650};

        var center_map = {lat: 50.042423, lng: 21.986650};

        var content1 = "<div class='google-maps-pin google-maps-pin_first'><div class='google-maps-pin__title'></div></div>";

        var styles = new google.maps.StyledMapType([
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "saturation": "-100"
                    },
                    {
                        "lightness": "30"
                    }
                ]
            },
            {
                "featureType": "administrative.neighborhood",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "gamma": "0.00"
                    },
                    {
                        "lightness": "74"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "3"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    }
                ]
            }

        ],
                {
                    name: "Style"
                }
        );

        var map = new google.maps.Map(document.getElementById('minimap'), {
            zoom: 14,
            center: center_map,
            scrollwheel: false,
            disableDefaultUI: true,
            mapTypeControlOptions: {
                mapTypeIds: []
            }
        });

        map.mapTypes.set("styles", styles);
        map.setMapTypeId('styles');

        var infowindow1 = new google.maps.InfoWindow({
            content: content1
        });


        var image = {
            url: '/themes/flatly/img/marker.png'
        };

        var marker = new google.maps.Marker({
            position: uluru,
            map: map,
            animation: google.maps.Animation.DROP,
            icon: image
        });

        infowindow1.open(map, marker);

        map.addListener('tilesloaded', function () {
            $('.google-maps-pin_first')
                    .css({
                        width: '75px',
                        height: '60px'
                    })

                    .css({
                        backgroundImage: "url(/themes/flatly/img/kontakt.jpg)",
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    });
        });
    }
}
;
var maps = new Maps();
jQuery(document).ready(function () {
    maps.init();
});